.card {
  background: $card-black-background;
  border: 0;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: $box-shadow;

  label {
    color: rgba($white, 0.6);
  }

  .card-title {
    margin-bottom: 0.75rem;
  }

  .card-body {
    padding: 15px;

    &.table-full-width {
      padding-left: 0;
      padding-right: 0;
    }

    .card-title {
      color: $white;
      text-transform: inherit;
      font-weight: $font-weight-light;
      margin-bottom: 0.75rem;
    }

    .card-description,
    .card-category {
      color: rgba($white, 0.6);
    }
  }

  .card-header {
    &:not([data-background-color]) {
      background-color: transparent;
    }
    padding: 0px 15px 0;
    border: 0;
    color: rgba($white, 0.8);

    .card-title {
      color: $white;
      font-weight: 100;
    }

    .card-category {
      color: $dark-gray;
      margin-bottom: 5px;
      font-weight: 300;
    }
  }

  .map {
    border-radius: $border-radius-sm;

    &.map-big {
      height: 420px;
    }
  }

  &.card-white {
    background: $white;

    .card-title {
      color: $black;
    }
    .card-category,
    .stats {
      color: $card-stats-gray;
    }

    //style for inputs

    @include form-control-placeholder(rgba($black, 0.4), 1);
    .has-danger {
      .form-control,
      .input-group-prepend .input-group-text {
        border-color: $danger-states;
      }
    }

    .input-group-prepend .input-group-text {
      border-color: rgba($black-states, 0.2);
      color: $black-states;
    }

    .form-control {
      color: $black;
      border-color: rgba($black-states, 0.2);
      &:focus {
        border-color: $primary;
      }
    }
    label:not(.btn) {
      color: $default;
    }

    .form-group.no-border,
    .input-group.no-border {
      .form-control,
      .form-control + .input-group-prepend .input-group-text,
      .form-control + .input-group-append .input-group-text,
      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text {
        background-color: $opacity-gray-3;
        &:focus,
        &:active,
        &:active {
          background-color: $opacity-gray-5;
        }
      }

      .form-control {
        &:focus {
          & + .input-group-prepend .input-group-text,
          & + .input-group-append .input-group-text {
            background-color: $transparent-bg;
          }
        }
      }
    }

    .input-group[disabled] {
      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text {
        background-color: $black;
      }
    }

    .form-control[disabled],
    fieldset[disabled] .form-control {
      background: $light-gray;
      border-color: rgba($black-states, 0.3);
    }

    .input-group-focus {
      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text,
      .form-control {
        background-color: $white;
        border-color: $primary;
      }

      &.no-border {
        .input-group-prepend .input-group-text,
        .input-group-append .input-group-text {
          background-color: $opacity-gray-5;
        }
      }
    }
    .input-group-prepend .input-group-text {
      border-right: none;
    }

    .input-group-append .input-group-text {
      border-left: none;
    }

    .has-danger .form-control:focus,
    .has-success.input-group-focus .input-group-append .input-group-text,
    .has-success.input-group-focus .input-group-prepend .input-group-text {
      border-color: $danger-states;
    }

    .has-success .form-control:focus,
    .has-success.input-group-focus .input-group-append .input-group-text,
    .has-success.input-group-focus .input-group-prepend .input-group-text {
      border-color: darken($success, 10%);
    }
  }

  &.card-plain {
    background: transparent;
    box-shadow: none;
  }

  .image {
    overflow: hidden;
    height: 200px;
    position: relative;
  }

  .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 15px;
  }

  label {
    font-size: $font-size-sm;
    margin-bottom: 5px;
  }

  .card-footer {
    background-color: transparent;
    border: 0;
    padding: 15px;

    .stats {
      i {
        margin-right: 5px;
        position: relative;
      }
    }

    h6 {
      margin-bottom: 0;
      padding: 7px 0;
    }
  }
}

.card-body {
  padding: $card-spacer-y;
}
